
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.blue-header {
    background-color: $dark-blue;
    color: $white;
    box-shadow: 0px 0px 20px -5px #898fd6;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 130px !important;

    + .content {
        margin-top: -120px;
    }
}

.content {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 10px 50px -40px #666666;
}

[role='menu'] {
    background-color: $white;
    box-shadow: 0px 5px 10px -5px $light-black;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    opacity: 1;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .content {
        left: 10px;
        width: calc(100% - 20px);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .content {
        left: 25px;
        width: calc(100% - 50px);
    }
}


                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

[role='menu'] {
    background-color: $white;
    box-shadow: 0px 5px 10px -5px $light-black;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    opacity: 1;
}

.cloud-progress {
    opacity: 0;

    &.is-loading {
        opacity: 1;
    }
}


                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.content {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 5px 10px -5px $grey-medium-light;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .content {
        left: 10px;
        width: calc(100% - 20px);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .content {
        left: 25px;
        width: calc(100% - 50px);
    }
}


                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '@/components/AList/AList.scss';

.keyword-loading-row {
    padding-top: 6px;
}

.keyword-row {
    box-shadow: 0px 5px 10px -5px $grey-medium-light;

    &.small {
        font-size: 80%;
    }
}


                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '@/components/AList/AList.scss';

.report-loading-row {
    padding-top: 6px;
}

.report-row {
    box-shadow: 0px 5px 10px -5px $grey-medium-light;

    &.small {
        font-size: 80%;
    }

    .status-chip .v-chip__content {
        display: flex;
        justify-content: center;
    }
}

[data-favicon] {
    position: relative;
    padding-left: 24px;

    &::before {
        position: absolute;
        content: '';
        display: block;
        background-image: var(--favicon);
        background-size: 16px 16px;
        width: 16px;
        height: 100%;
        top: calc(50% - 8px);
        left: 0;
    }
}

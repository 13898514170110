
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.report-banner::v-deep {
    position: relative;
    border-radius: 8px;
    background-position: left center;
    min-height: 200px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('@/assets/img/report-banner/bottom-art.svg');
        background-position: left center;
        border-radius: 8px;
    }

    .banner-action {
        min-height: 200px;
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .report-banner::before {
        background-size: 55%;
        background-position: bottom left;
    }
}


                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.back-button {
    color: $primary-color;
}

.report-column {
    z-index: 1;
}

.bottom-navigation {
    z-index: 2;
}

.menu__open::before {
    opacity: 0.04;
}
